.my-col-5-3 {
  @include col-custom(4.1);
}
.my-col-3-9 {
  @include offset-custom(1.2);
  @include col-custom(3.9);
}
.my-col-2-8 {
  @include col-custom(2.8);
}
// [class^="page--"] {
//  padding-top: 70px;
//  @include breakpoint-down(md) {
//   padding-top: 56px;
//  }
// }
// [class^="page--"] {
//   margin-bottom: 100px;
//   @include breakpoint-down(md) {
//     margin-bottom: 32px;
//     #{$template-main} & {
//       margin-bottom: 0;
//     }
//   }
//   #{$template-main} & {
//     margin-bottom: 0;
//   }
// }
.panel-group {
  .collapse {
    display: none;
  }
  .in {
     display: block;
  }
  .collapsing {
     position: relative;
     height: 0;
     overflow: hidden;
     transition: height .35s ease;
  }
}
.panel-common {
  .panel-default {
    border-bottom: 1px solid #C9DAF4;
    padding: 30px 0 20px;
  }
  .panel-title {
    a {
      color: #000;
    }
  }
  // .is-open {
  //   background: $brand-primary-2;
  //   border-color: transparent;
  //   .panel-title {
  //     a {
  //       color: #fff;
  //     }
  //   }
  // }
  .panel-body {
    padding-top: 8px;
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .is-open {
    .panel-title {
      span {
        &::before {
          opacity: 0;
        }
      }
    }
  }
  .panel-title {
    position: relative;
    @include title-4;
    padding-right: 24px;
    font-weight: 600;
    padding-left: 45px;
    // .collapsed {
    //   span {
    //     // transform: rotate(0deg);
    //   }
    // }
    span {
      position: absolute;
      top: 3px;
      left: 0;
      background-color: $brand-primary;
      // transform: rotate(180deg);
      // transition: transform .4s ease-in-out;
      width: 30px;
      height: 30px;
      display: block;
      @include breakpoint-down(md) {
        top: -3px;
      }
      &::before {
        content: '';
        height: 16px;
        width: 2px;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        // opacity: 0;
      }
      &::after {
        content: '';
        width: 16px;
        height: 2px;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%)
      }
    }
  }
}
.page-title {
  @include title-1;
  font-weight: 600;
  margin-bottom: 48px;
}
.page-link {
  list-style: none;
  padding-left: 0;
  margin-bottom: 48px;
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 48px;
    }
    a {
      color: $brand-secondary;
      @include title-1;
    }
  }
  .active {
    a {
      color: $brand-primary;
    }
  }
}
.padding-top-header {
  padding-top: 100px;
  @include breakpoint-down(md) {
    padding-top: 56px;
  }
}
[x-cloak] {
  display: none !important;
}
