.section-send-block-list {
  .item-list {
    margin-bottom: 16px;
    min-height: 264px;
    &-wrap {
      border-radius: 4px;
      background-color: $brand-primary-2;
      padding: 24px;
      height: 100%;
    }
    .media {
      margin-bottom: 16px;
    }
    .title {
      font-family: "Poppins";
      @include title-4;
      font-weight: 600;
      margin-bottom: 13px;
    }
  }
}
