.tab-pane {
	display: none;
	&.active {
		display: block;
	}
}
.content-nav-tabs {
	@include list-unstyled;
	display: inline-flex;
	border-radius: $input-border-radius;
	border: 1px solid $brand-primary;
	margin-bottom: 36px;
	flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 0 !important;
  width: 100%;
	li {
    // flex: 0 0 auto;;
  width: 100%;
    &::before {
      display: none;
    }
		a {
			display: block;
			text-align: center;
			padding: 13px 16px;
			color: $brand-primary;
		}
		&.active {
			a {
				background: $brand-primary;
				color: #fff;
			}
		}
		&:not(:last-child){
			a {
				border-right: 1px solid $brand-primary;
			}
		}
  }
}
