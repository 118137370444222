.footer {
  background-color: $brand-primary;
  padding: 56px 0 76px;
  @include breakpoint-down(md) {
    padding: 24px 0 8px;
  }
  [class^="col-lg-"] {
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  .logo {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 200;
    color: #fff;
    display: inline-block;
    margin-right: 24px;
    span {
      color: $brand-secondary;
      font-weight: 700;
    }
    &-dna {
      display: inline-block;
      max-width: 169px;
    }
  }
  &-sup {
    margin-bottom: 28px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  &-bottom {
    padding-top: 24px;
    color: rgba(255, 255, 255, 0.56);
    @include text-caption;
    a {
      color: inherit;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer-title {
    font-weight: 600;
    font-family: 'Poppins';
    color: #fff;
    margin-bottom: 8px;
  }
  .footer-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    a {
      opacity: 0.8;
      color: #fff;
      @include text-caption;
    }
    &-link {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      a {
        display: flex;
        align-items: center;
        opacity: 0.8;
        color: #fff;
        @include text-caption;
        .icon {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}
