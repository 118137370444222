.section-carousel {
  .section-title {
    text-align: center;
  }
  .section-content {
    position: relative;
  }
  .swiper-slide {
    height: auto;
  }
  .item-carousel {
    padding: 32px 13px 26px;
    text-align: center;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background-color: $gray-3;
    height: 100%;
  }
  .item-media {
    width: 176px;
    height: 176px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto 24px;
  }
  .img {
    @include stretch;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  .item-title {
    font-family: 'Poppins';
    font-weight: 700;
    margin-bottom: 6px;
  }
  .item-desc {
    @include text-small;
  }
  .item-meta {
    color: $brand-primary;
    @include text-small;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  .swiper-wrapper {
    margin-bottom: 12px;
  }
  &-footer {
    text-align: center;
    .media {
      margin-bottom: 16px;
      img {
        max-height: 40px;
        width: 100%;
      }
      &-trustpilot {
        img {
          max-height: 40px;
        }
      }
    }
    .desc {
      color: $gray;
      margin-bottom: 16px;
    }
    a {
      display: block;
    }
  }
  .section-footer {
    padding-top: 24px;
  }
  .section-carousel-controls {
    @include breakpoint-up(sm) {
      display: none;
    }
    @include breakpoint-down(sm) {
      margin: 0 12px;
    }
  }
}

.swiper-pagination-controls {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
