// **********************************************************************************
$input-font-size: $font-size-base !default;
$input-border-width: 1px !default;
$input-border-radius: 4px !default;
$input-height-base: 48px !default;
$form-group-margin-bottom:  16px !default;
// *************************************************************************

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0;
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	font-size: $font-size-base;
	line-height: inherit;
	border: 0;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

input[type="search"] {
	box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9; // IE8-9
	line-height: normal;
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

select[multiple],
select[size] {
	height: auto;
}

output {
	display: block;
	margin: 0;
	padding: 0;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $input-color;
}

.form-control {
	display: block;
	width: 100%;
	height: $input-height-base;
	padding: 6px 12px;
	font-size: $input-font-size;
	line-height: 1.42857143;
	color: $input-color;
	background-color: $input-bg;
	background-image: none;
  border: $input-border-width solid $input-border;
  // border: none;
	border-radius: $input-border-radius;
  // box-shadow: inset 0 1px 1px rgba(black,.075);
  box-shadow: none;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	outline: none !important;
	&::placeholder {
		color: $input-color-placeholder;
	}

	&::-ms-expand {
		border: 0;
		background-color: transparent;
	}

	&:focus {
		&::placeholder {
			color: rgba($input-color-placeholder,.5);
		}
		$color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);
		border-color: $input-border-focus;
		box-shadow: inset 0 1px 1px rgba(black,.075), 0 0 8px $color-rgba;
	}

	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		background-color: $input-bg-disabled;
		opacity: 1;
	}

	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}
  &-title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  &.is-invalid {
    border-color: $red;
    color: $red;
    &:focus {
      border-color: inherit;
      box-shadow: none;
    }
  }
}

textarea.form-control {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		&.form-control {
			line-height: $input-height-base;
		}
	}
}
.form-control-container {
	position: relative;
	.form-control-placeholder {
	  position: absolute;
	  top: 15px;
	  left: 12px;
	  pointer-events: none;
	  color: $input-color-placeholder;
	  &.moved {
		left: initial;
		right: 12px;
	  }
	}
	.form-control-js-text {
	  visibility: hidden;
	  height: 0;
	  pointer-events: none;
	  position: absolute;
	}
}
.invalid-feedback {
  list-style: none;
  color: $red;
  @include text-small;
  position: absolute;
  top: 100%;
  left: 0;
  padding-left: 0 !important;
  margin-bottom: 0;
  li {
    line-height: 1.4 !important;
    &::before {
      display: none;
    }
  }
}
.form-group {
	margin-bottom: $form-group-margin-bottom;
	// padding-bottom: 15px;
	position: relative;
//   @include breakpoint-down(md) {
//     margin-bottom: 0;
//   }
  .form-field-error-label {
    position: absolute;
  }
	&.has-error {
		.form-field-error-label {
			color: $red;
			@include text-small;
			position: absolute;
			top: 100%;
			left: 0;
		}
		.form-control {
			border-color: $red;
			color: $red;
			&:focus {
				border-color: inherit;
				box-shadow: none;
			}
		}
		.form-control-action {
			display: none;
		}
		// @extend .icon-exclamation;
		// &::before {
		// 	font-family: 'iconfont';
		// 	display: block;
		// 	position: absolute;
		// 	top: 16px;
		// 	right: 16px;
		// 	color: $red;
		// 	font-size: 20px;
		// }
	}
	&.is-valid {
		// @extend .icon-tick;
		// &::before {
		// 	font-family: 'iconfont';
		// 	display: block;
		// 	position: absolute;
		// 	top: 16px;
		// 	right: 16px;
		// 	color: #00a06f;
		// 	font-size: 16px;
		// }
		.form-control-action {
			display: none;
		}
	}
	.form-control-action {
		position: absolute;
		right: 20px;
    top: 18px;
  }
  &-desc {
    margin-bottom: 32px;
  }
  &-title {
    margin-bottom: 32px;
    @include text-large;
    font-weight: bold;
  }
}
.m-b-40 {
 margin-bottom: 40px;
}
.m-b-32 {
  margin-bottom: 32px;
}
.checkbox  {
  font-weight: normal;
  margin-bottom: 0;
  position: relative;
  padding-left: 40px;
  line-height: 16px;
	input {position: absolute; left: -9999px; width: 0; height: 0;}
	span{
    font-size: 12px;
    line-height: 14px;
    @include breakpoint-down(md) {
      font-size: 11px;
    }
    a {
      font-weight: 700;
      text-decoration: underline;
      color: #000;
    }
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      // transform: translateY(-50%);
      left: 3px;
      width: 18px;
      height: 18px;
      border: 2px solid #b6b8bb;
      border-radius: 3px;
      // @include breakpoint-down(md) {
      //   transform: translateY(0%);
      //   top: 3px;
      // }
    }
    .icon {
      position: absolute;
      transform: translateY(0%);
      top: 5px;
      left: 3px;
      font-size: 14px;
      width: 18px;
      height: 18px;
      color: $brand-primary;
      opacity: 0;
      z-index: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
	}
	input:checked + span {
    &:before {
      // background: $brand-secondary;
      border-color: $brand-primary;
    }
    .icon {
      opacity: 1;
    }
	}
}
.form-group-custom {
  &.has-error {
    .form-field-error-label {
      opacity: 1;
    }
  }
  .form-field-error-label {
    opacity: 0;
  }
}
.checkbox-custom-list {
  display: flex;
	flex-wrap: nowrap;
  margin: 0 -12px;
  position: relative;
  @include breakpoint-down(md) {
   overflow-x: auto;
  }
}
.checkbox-custom  {
  font-weight: normal;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  padding: 0 12px;
	input {position: absolute; left: -9999px; width: 0; height: 0;}
	span{
    position: relative;
    font-size: 16px;
    padding: 19px 8px 8px;
    border-radius: 16px;
    background-color: #F6F6F6;
    display: block;
    width: 100%;
    text-align: center;
    .section-form-bg & {
      background-color: #fff;
    }
    img {
           margin: 0 auto 12px;
    }
    // @include breakpoint-down(md) {
    //   padding: 16px 16px 16px 52px;
    // }
    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   left: 16px;
    //   width: 18px;
    //   height: 18px;
    //   border: 1px solid #b6b8bb;;
    //   border-radius: 3px;
    //   transform: translateY(-50%);
    //   @include breakpoint-down(md) {
    //     transform: translateY(0%);
    //     top: 18px;
    //   }
    // }
    // .icon {
    //   position: absolute;
    //   top: 50%;
    //   left: 17px;
    //   font-size: 14px;
    //   width: 18px;
    //   height: 18px;
    //   color: #4BB8A9;
    //   opacity: 0;
    //   z-index: 1;
    //   display: inline-flex;
    //   align-items: center;
    //   justify-content: center;
    //   transform: translateY(-50%);
    //   @include breakpoint-down(md) {
    //     transform: translateY(0%);
    //     top: 18px;
    //   }
    // }
	}
	input:checked + span {
    background: #4BB8A9;
    color: #fff;
    &:before {
      background: #fff;
      border-color: #fff;
    }
    .icon {
      opacity: 1;
    }
	}
}
.alert-success {
	display: none;
  @include title-2;
  font-family: 'Poppins';
  padding-top: 40px;
  .alert-success-title {
    margin-bottom: 11px;
    font-weight: 700;
  }
  .alert-success-desc {
    margin-bottom: 11px;
    font-weight: 300;
  }
  img {
    display: block;
    margin-bottom: 100px;
    @include breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
}
.btn-submit-block {
  text-align: center;
  @include breakpoint-down(md) {
   text-align: initial;
   padding-left: 40px;
  }
}
.choices {
  margin-bottom: 0;
	// &[data-type*=select-one] {
	// 	@extend .icon-chevron-down;
	// 	&::after {
	// 		display: none;
	// 	}
	// 	&::before {
	// 		font-family: 'iconfont';
	// 		position: absolute;
  //     right: 11.5px;
	// 		font-size: 14px;
  //     transition: transform .2s ease;
  //     color: #000;
  //     z-index: 3;
  //     top: 50%;
  //     transform: translate(0%, -50%) rotate(0deg)
	// 	}
	// 	&.is-open {
  //     &::before {
  //       transform: translate(0%, -50%) rotate(180deg)
  //     }
	// 	}
	// }
}
.radio-button-list {
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid $brand-primary;
  border-radius: $input-border-radius;
}
.radio-button  {
  font-weight: normal;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  input {position: absolute; left: -9999px; width: 0; height: 0;}
  &:not(:last-child) {
    span {
      &::before {
        content: '';
        position: absolute;
        left: 100%;
        width: 1px;
        top: 8px;
        bottom: 8px;
        background: $brand-primary;
      }
    }
  }
	span{
    padding: 12px;
    font-weight: 600;
    @include text-normal;
    display: block;
    width: 100%;
    text-align: center;
    color: $brand-primary;
    border-radius: $input-border-radius;
    position: relative;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   left: 16px;
    //   width: 18px;
    //   height: 18px;
    //   border: 1px solid #b6b8bb;;
    //   border-radius: 3px;
    //   transform: translateY(-50%);
    //   @include breakpoint-down(md) {
    //     transform: translateY(0%);
    //     top: 18px;
    //   }
    // }
	}
	input:checked + span {
    background: #4BB8A9;
    color: #fff;
    &:before {
      background: #fff;
      border-color: #fff;
    }
	}
}
.choices__inner {
  display: block;
	width: 100%;
	border: 1px solid $input-border;
  padding: 6px 28px 6px 16px;
  height: 56px;
  border-radius: $input-border-radius;
  background: $input-bg;
  position: relative;
  z-index: 2;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  .is-open & {
    border-color: $input-border;
    border-radius: $input-border-radius $input-border-radius 0 0;
    border-bottom-color: transparent;
  }
  .choices__list--single {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
  }
}
.choices__list--dropdown {
  padding: 0;
  border-radius: 0 0 $input-border-radius $input-border-radius;
	background: #fff;
  color: #000;
  border: 1px solid $input-border;
  border-top: none;
  z-index: 4;
  // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  .choices__item {
    font-size: 16px;
    line-height: 20px;
    padding: 6px 16px;
    min-height: 48px;
    display: flex;
    align-items: center;
    &.is-selected {
      display: none;
    }
  }
  .choices__placeholder {
    display: none;
  }
}
