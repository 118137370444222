.section-tariffs {
  .section-title {
    @include breakpoint-down(sm) {
      text-align: center;
    }
  }
  .section-head {
    @include breakpoint-down(sm) {
      margin-bottom: 38px;
    }
  }
  .tabs {
    &-header {
      display: none;
      margin-bottom: 24px;
      overflow-x: auto;
      @include breakpoint-down(sm) {
        display: block;
        text-align: center;
      }
      button {
        font-weight: 700;
        font-family: 'Poppins';
        color: $gray-4;
        margin-right: 48px;
        border-bottom: 2px solid transparent;
        @include breakpoint-down(sm) {
          margin-right: 12px;
          margin-left: 12px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .isActive {
        color: #000;
        border-color: $brand-primary;
      }
    }
    &-body {
      @include create-row();
    }
  }
  .item-tabs {
    @include col-custom(6);
    @include breakpoint-down(sm) {
      width: 100%;
    }
    &-wrap {
      padding: 16px 14px;
      height: 100%;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      @include breakpoint-down(sm) {
        padding: 16px 16px 24px;
      }
    }
    .item-content {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }
    .item-title {
      @include title-4;
      font-weight: bold;
      font-family: 'Poppins';
      @include breakpoint-down(sm) {
        display: none;
      }
    }
    .item-badge {
      display: inline-flex;
      align-items: center;
      border-radius: 17px;
      background-color: $brand-secondary;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 700;
      padding: 5px 12px;
      .icon {
        font-size: 20px;
        margin-right: 10px;
      }
    }
    .item-desc {
      @include text-small;
      @include breakpoint-down(sm) {
       @include text-caption;
       line-height: 15px;
      }
    }
    .item-head {
      margin-bottom: 33px;
      @include breakpoint-down(sm) {
        margin-bottom: 16px;
      }
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 13px;
        @include breakpoint-down(sm) {
          margin-bottom: 16px;
        }
      }
    }
    .mian-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
    .mian-item {
      width: 50%;
      margin-bottom: 24px;
      @include breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 16px;
      }
      &:nth-child(odd) {
        padding-right: 12px;
        @include breakpoint-down(sm) {
          padding-right: 0;
        }
      }
      &:nth-child(even) {
        padding-left: 12px;
        @include breakpoint-down(sm) {
          padding-left: 0;
        }
      }
      &-desc {
        font-family: 'Poppins';
        font-size: 18px;
        font-weight: 400;
        strong {
          font-size: 36px;
          font-weight: 700;
          line-height: 45px;
          span {
            font-size: 24px;
            line-height: 1.4;
          }
        }
      }
      &-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        font-family: 'Poppins';
      }
    }
    .item-list {
      @include list-unstyled;
      margin-bottom: 30px;
      li {
        padding-left: 40px;
        @include text-small;
        font-family: 'Poppins';
        line-height: 40px;
        @include breakpoint-down(sm) {
          font-size: 12px;
        }
        &::before {
          display: none;
        }
        img {
          position: absolute;
          left: 0;
          top: 11px;
        }
      }
    }
  }
}
